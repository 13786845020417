<template>
  <ui-side-modal v-show="show"
                 class="template-detail"
                 :width="modalWidth"
                 @close="closeModal">
    <ui-detail v-if="dataOption && dataInHand"
               :meta="dataOption"
               :data="dataInHand"
               :error="error"
               :editMode="editMode"
               :tableSortBy="tableSortBy"
               @set="setData"
               @error="setError"
               @input="input"
               @changeTableSort="changeTableSort"
               @button="button">

      <slot></slot>
      <template v-if="menu === 'post'
                   || menu === 'postReply'
                   || menu === 'topPost'
                   || menu === 'reportContent'
                   || menu === 'review'">
        <ui-button :color="'blue'"
                   @click="goLink">
          <i class="xi-link"></i> 바로가기
        </ui-button>
      </template>
      <template v-if="canRemove && !editMode">
        <ui-button class="remove-button"
                   :color="'gray'"
                   @click="remove">
          <i class="xi-trash"></i> 삭제
        </ui-button>
      </template>
      <template v-if="canEdit">
        <template v-if="editMode">
          <ui-button :color="'gray'"
                     @click="save(false)">
            <i class="xi-close"></i> 취소
          </ui-button>
          <ui-button :color="'red'"
                     @click="save(true)">
            <i class="xi-pen"></i> 저장
          </ui-button>
        </template>
        <template v-else>
          <ui-button :color="'yellow'"
                     @click="edit">
            <i class="xi-pen"></i> 수정
          </ui-button>
        </template>
      </template>
    </ui-detail>
  </ui-side-modal>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError, isEndWithConsonant } from '@/utils/tools'
import { ERR_MSG, FORUM_HOME_URL, NODE_ENV } from '@/utils/constants'

import UiDetail from '@/components/_ui/UiDetail'
import UiButton from '@/components/_ui/UiButton'
import UiSideModal from '@/components/_ui/UiSideModal'
import { mapGetters } from 'vuex'

export default {
  name: 'TemplateDetail',
  components: {
    UiDetail,
    UiButton,
    UiSideModal
  },
  props: {
    infoApi: {
      required: true
    },
    editApi: {
      required: false
    },
    removeApi: {
      required: false
    },
    apiParam: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    menu: {
      required: false
    },
    rank: {
      required: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    modalWidth () {
      if (this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.type === 'editor' || option.type === 'editor4') {
            return 1200
          }
        }
      }
      return null
    },
    canEdit () {
      if (this.editApi && this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if ('editable' in option && option.editable) {
            return true
          }
        }
      }
      return false
    },
    canRemove () {
      if (this.removeApi && this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.key && option.deletable) {
            return true
          }
        }
      }
      return false
    }
  },
  created () {
    if (this.apiParam) {
      this.loadData()
    }
  },
  watch: {
    reload () {
      this.loadData()
    },
    apiParam (value) {
      this.editMode = false
      if (value) {
        console.log('parent watch')
        this.loadData()
      }
    },
    editMode (value) {
      if (value) {
        this.setModel()
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    loadData (callback = null) {
      this.error = {}
      this.show = false
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        this.infoApi(this.apiParam, true).then(response => {
          this.dataInHand = response.result.info || null
          if (this.dataInHand) {
            this.dataInHand.rank = this.rank
          }
          this.dataOption = response.result.meta || null
          this.show = true

          switch (this.dataInHand.type) {
            case 'forum':
            case 'casestudy':
            case 'notice':
            case 'career':
              if (this.dataInHand.isAdmin === 1) {
                return this.dataOption.field.reportState.editable = false
              }
              break
            default:
              return null
          }

        }).catch(error => {
          console.error('error', error)
          alertError('상세 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          if (callback) {
            callback()
          }
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    setModel () {
      this.model = {}
      for (const [field, option] of Object.entries(this.dataOption.field)) {
        if (option.key || option.editable) {
          this.model[option.field || field] = this.dataInHand[option.field || field]
        }
      }
    },
    edit () {
      this.editMode = !this.editMode
      if (this.dataInHand.postState === 'hide') {
        this.dataOption.field.referenceState.editable = false
      }
    },
    setData (data) {
      console.log(data)
    },
    setError (key, message) {
      this.$set(this.error, key, message)
    },
    checkRequired () {
      if (this.dataOption) {
        for (const [field, option] of Object.entries(this.dataOption.field)) {
          const key = option.field || field
          if (option.required && !this.model[key] && this.model[key] !== 0) {
            this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
          }
        }
      }
      for (const [field, message] of Object.entries(this.error)) {
        if (message) {
          if (message === '확인 버튼을 눌러 확인하세요.') {
            this.model[field] = ''
          } else {
            return false
          }
        }
      }
      return true
    },
    goLink () {
      let dataId = null
      if (this.menu === 'postReply' || this.menu === 'reportContent') {
        dataId = this.dataInHand.postId
      } else if (this.menu === 'review') {
        let URL = 'https://staging-forum.beusable.net/ko/solomon/post/'
        if (NODE_ENV === 'production') {
          URL = 'https://forum.beusable.net/ko/solomon/post/'
        }
        dataId = this.dataInHand.postId
        return window.open(URL + dataId)
      } else {
        dataId = this.dataInHand.id
      }
      const url = FORUM_HOME_URL + dataId
      window.open(url, '_blank')
    },
    remove () {
      let comment = ''
      if (this.menu === 'post') {
        comment = '삭제 시 게시글에 달린 답글도 모두 삭제되며, 복구할 수 없습니다. 정말 삭제하시겠습니까?'
      } else if (this.menu === 'postReply') {
        comment = '삭제 시 답변에 달린 답글도 모두 삭제되며, 복구할 수 없습니다. 정말 삭제하시겠습니까?'
      } else {
        comment = '삭제 시 복구할 수 없습니다. 정말 삭제하시겠습니까?'
      }
      const confirmed = confirm(comment)
      if (confirmed) {
        EventBus.$emit('onLoading', true)
        this.removeApi({ id: this.dataInHand.id }, true).then(response => {
          this.show = false
        }).catch(error => {
          alertError('삭제에 실패했습니다.', error)
        }).finally(() => {
          this.$emit('reload')
          EventBus.$emit('onLoading', false)
        })
      }
    },
    save (save = false) {
      let confirmed = false
      if (this.checkRequired()) {
        confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
      }

      if (confirmed) {
        if (save) {
          EventBus.$emit('onLoading', true)
          if (this.menu === 'user') {
            this.model.id = this.dataInHand.id
          } else {
            this.model.userData = this.userData
          }
          this.editApi(this.model, true).then(() => {
            this.loadData(this.edit)
          }).catch(error => {
            if (error.message === ERR_MSG.UNDER_TEN_KEYWORD) {
              alertError('인기 태그를 10개 미만으로 노출할 수 없습니다. \n노출 상태를 다시 설정해 주세요.', error)
            } else if (error.message === ERR_MSG.UNDER_FIVE_POST) {
              alertError('인기 게시글을 5개 미만으로 노출할 수 없습니다. \n노출 상태를 다시 설정해 주세요.', error)
            } else {
              alertError('저장에 실패했습니다.', error)
            }
          }).finally(() => {
            this.$emit('reload')
            EventBus.$emit('onLoading', false)
          })
        } else {
          this.loadData(this.edit)
        }
      }
    },
    input (field, value) {
      this.$delete(this.error, field)
      this.model[field] = value

      if (this.model.postState === 'hide' || this.model.reportState === 'adminReport') {
        this.dataOption.field.referenceState.editable = false
        this.model.referenceState = 'unreferenced'
        this.dataInHand.referenceState = 'unreferenced'
      } else {
        if (this.dataOption.field.referenceState) this.dataOption.field.referenceState.editable = true
      }

      this.$emit('input', field, value)
    },
    changeTableSort (field, sort) {
      this.tableSortBy[field] = sort
      const desc = sort.indexOf('-') === 0
      sort = sort.replace('-', '').replace('+', '')
      this.dataInHand[field].sort((a, b) => {
        if (desc) {
          return a[sort] > b[sort] ? -1 : 1
        } else {
          return a[sort] < b[sort] ? -1 : 1
        }
      })
    },
    button (value, data) {
      let dataForSearch = data
      if (this.menu === 'keyword') {
        dataForSearch = this.dataInHand.keyword
      } else if (this.menu === 'reportContent') {
        dataForSearch = this.dataInHand.contentId
      }
      if (value == 'deleteUser') {
        this.$emit('deleteUser', value)
      } else {
        this.$emit('button', value, dataForSearch)
      }
    },
    closeModal () {
      let confirmed = true
      if (this.editMode) {
        confirmed = confirm('정말로 저장하지 않고 끝내시겠습니까?')
      }
      if (confirmed) {
        this.show = false
        this.$emit('close')
      }
    },
  },
  data () {
    return {
      dataInHand: null,
      dataOption: null,
      editMode: false,
      show: false,
      model: {
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable'
      },
      error: {},
      tableSortBy: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .remove-button {
    opacity: 0.5;
  }
}
</style>
