export const NODE_ENV = process.env.VUE_APP_NODE_ENV || 'development'

let home = process.env.VUE_APP_HOME_BASE_URL || 'http://localhost:3000'
if (NODE_ENV === 'staging') {
  home = 'https://staging-home.beusable.net'
} else if (NODE_ENV === 'production') {
  home = 'https://www.beusable.net'
}
export const BEUSABLE_HOME_URL = home

let mailer = process.env.VUE_APP_MAILER_BASE_URL || 'http://localhost:8092/beusable-mailer/addMailQueue'
if (NODE_ENV === 'staging' || NODE_ENV === 'production') {
  mailer = 'https://52.79.145.34/beusable-mailer/addMailQueue'
}

export const MAILER_URL = mailer

let dreamHost = process.env.VUE_APP_DREAM_BASE_URL || 'http://localhost:8540'
if (NODE_ENV === 'staging') {
  dreamHost = 'https://dream.beusable.net'
} else if (NODE_ENV === 'production') {
  dreamHost = 'https://tool.beusable.net'
}

export const BEUSABLE_URL = dreamHost

let beusablyHome = process.env.VUE_APP_BEUSABLY_HOME_BASE_URL || 'http://localhost:3302'
if (NODE_ENV === 'staging') {
  beusablyHome = 'https://staging.beusably.net'
} else if (NODE_ENV === 'production') {
  beusablyHome = 'https://www.beusably.net'
}

export const BEUSABLY_URL = beusablyHome

let renderer = process.env.VUE_APP_RENDERER_BASE_URL || 'http://localhost:8092'
if (NODE_ENV === 'production') {
  renderer = 'https://renderer-api.beusable.net'
} else if (NODE_ENV === 'staging') {
  renderer = 'https://staging-renderer-api.beusable.net'
}

export const RENDERER_API_URL = renderer

let ba = process.env.VUE_APP_ANALYTICS_URL || 'http://localhost:8550'
if (NODE_ENV === 'staging') {
  ba = 'http://staging-analytics.beusable.net/tool'
} else if (NODE_ENV === 'production') {
  ba = 'https://analytics.beusable.net/tool'
}

export const BA_URL = ba

let baHome = process.env.VUE_APP_ANALYTICS_HOME_URL || 'http://localhost:3050'
if (NODE_ENV === 'staging') {
  baHome = 'https://staging-analytics.beusable.net'
} else if (NODE_ENV === 'production') {
  baHome = 'https://analytics.beusable.net'
}

export const BA_HOME_URL = baHome

export const STORAGE_ITEM = {
  searchData: {}
}

export const STORAGE_KEY = {
  devTimestamp: 'devTimestamp'
}

export const ERR_MSG = {
  UNDER_TEN_KEYWORD: 'under_ten_err',
  UNDER_FIVE_POST: 'under_five_err'
}

let forumHome = 'http://localhost:8900/ko/post/'
if (NODE_ENV === 'staging') {
  forumHome = 'https://staging-forum.beusable.net/ko/post/'
} else if (NODE_ENV === 'production') {
  forumHome = 'https://forum.beusable.net/ko/post/'
}
export const FORUM_HOME_URL = forumHome

export const DEFAULT_LANG = 'ko'
